import React from 'react'
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import { TiSocialFacebook, TiSocialTwitter, TiSocialInstagram, TiSocialLinkedin } from "react-icons/ti";
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import Logo from '../../../../assets/images/logo/dark-logo-160x48.svg'
import lightLogo from '../../../../assets/images/logo/light-logo-160x48.svg'
// import gplayImage from '../../../../assets//images/icons/aeroland-button-google-play.jpg'
// import apnPartnerImage from '../../../../assets//images/icons/apn-partner.png'
// import clutchImage from '../../../../assets//images/icons/clutch_logo.png'
// import appImage from '../../../../assets//images/icons/aeroland-button-app-store.jpg'
import { Row, Col } from '../../../../components/ui/wrapper'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
// import Button from '../../../../components/ui/button'
import Heading from '../../../../components/ui/heading'
import Social, { SocialLink } from '../../../../components/ui/social'
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    LogoWidget,
    TextWidget,
    FooterWidgetList,
    FooterBottom
} from './footer.style'

import CookieConsent from "react-cookie-consent";

const Footer = ({ copyrightStyle, ...props }) => { 
    const siteInfo = useStaticQuery(graphql`
        query FooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                    contact {
                        
                        email
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
                }
            }
        }      
    `)
    const { phone, email, address } = siteInfo.site.siteMetadata.contact;
    const { copyright } = siteInfo.site.siteMetadata;
    const { facebook, twitter, instagram, linkedin } = siteInfo.site.siteMetadata.social;
    return (
        <FooterWrap {...props}>
            <FooterTop>
                <Container>
                    <Row>
                        <Col lg={4} sm={12}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <LogoWidget>
                                    <img className="dark_logo" src={Logo} width="260px" height="80px" alt="Logo" />
                                    <img className="light_logo" src={lightLogo} width="260px" height="80px" alt="Logo"/>
                                </LogoWidget>
                                <TextWidget>
                                    {address && <Text mb="10px">{address}</Text>}
                                    {email && <Text mb="10px"><Anchor path={`mailto:${email}`} color="textColor" hoverstyle="2">{email}</Anchor></Text>}
                                    {/* {phone && <Text mb="10px"><Anchor path={`tel:${phone}`} fontWeight="800" color="#333" hoverstyle="2">{phone}</Anchor></Text>}*/}
                                    {/* {website && <Text mb="10px"><Anchor path={website} hoverstyle="2">{website}</Anchor></Text>} */}
                                </TextWidget>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px"><Anchor path="/it-services" color="inherit" hoverstyle="2">Services</Anchor></Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/it-service/consult" color="textColor" hoverstyle="2">Consult</Anchor></li>
                                    <li><Anchor path="/it-service/design" color="textColor" hoverstyle="2">Design</Anchor></li>
                                    <li><Anchor path="/it-service/engineer" color="textColor" hoverstyle="2">Engineer</Anchor></li>
                                    <li><Anchor path="/it-service/operate" color="textColor" hoverstyle="2">Operate</Anchor></li>
                                    <li><Anchor path="/it-service/optimize" color="textColor" hoverstyle="2">Optimize</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <FooterWidget responsive={{ medium: { mb: '27px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px"><Anchor path="/it-solutions" color="inherit" hoverstyle="2">Solutions</Anchor></Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/it-solution/mdforce-tailored-healthcare-automation" color="textColor" hoverstyle="2">MDforce</Anchor></li>
                                    <li><Anchor path="/it-solution/cryptoconnect-point-of-sales-application" color="textColor" hoverstyle="2">CryptoConnect</Anchor></li>
                                    <li><Anchor path="/it-solution/recaptcha-for-ml-research-companies" color="textColor" hoverstyle="2">CaptchaChain</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <FooterWidget>
                                <Heading as="h6" mt="-3px" mb="20px"><Anchor path="/about-us" color="inherit" hoverstyle="2">About Us</Anchor></Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/about-us" color="textColor" hoverstyle="2">About</Anchor></li>
                                    <li><Anchor path="/agile-culture" color="textColor" hoverstyle="2">Agile Culture</Anchor></li>
                                    <li><Anchor path="/case-studies" color="textColor" hoverstyle="2">Case Studies</Anchor></li>
                                    <li><Anchor path="/blog" color="textColor" hoverstyle="2">Blog</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={3} sm={6} xs={6}>
                            <FooterWidget mt='50px' responsive={{ small: { mt: '34px' } }}>
                                <FooterWidgetList>
                                    <li><Anchor path="/contact-us" color="textColor" hoverstyle="2">Contact Us</Anchor></li>
                                    <li><Anchor path="https://angel.co/company/sixvertex-software/jobs" target="_blank" color="textColor" hoverstyle="2" rel="noopener noreferrer">Careers</Anchor></li>
                                    <li><Anchor path="/privacy-policy" color="textColor" hoverstyle="2">Privacy Policy</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={6} className="text-center text-md-left">
                            {copyright && <Text {...copyrightStyle}>&copy; {new Date().getFullYear()} {parse(copyright)}</Text>}
                        </Col>
                        <Col md={6} className="text-center text-md-right">
                            <Social space="8px" skin="primary" tooltip={true} size="large">
                                {twitter && (
                                    <SocialLink
                                        path={twitter}
                                        title="Twitter">
                                        <TiSocialTwitter />
                                        <span className="readers-only">Twitter</span>
                                    </SocialLink>
                                )}
                                {facebook && (
                                    <SocialLink
                                        path={facebook}
                                        title="Facebook">
                                        <TiSocialFacebook />
                                        <span className="readers-only">Facebook</span>
                                    </SocialLink>
                                )}
                                {instagram && (
                                    <SocialLink
                                        path={instagram}
                                        title="Instagram">
                                        <TiSocialInstagram />
                                        <span className="readers-only">Instagram</span>
                                    </SocialLink>
                                )}
                                {linkedin && (
                                    <SocialLink
                                        path={linkedin}
                                        title="Linkedin">
                                        <TiSocialLinkedin />
                                        <span className="readers-only">LinkedIn</span>
                                    </SocialLink>
                                )}
                            </Social>
                        </Col>
                    </Row>
                    <Row className="center-content">
                        <CookieConsent
                            buttonText="OKAY, GOT IT!"
                            cookieName="cookieConsent"
                            expires={150}
                        >
                            We use cookies to optimize your user experience on our site. To find out more, read our updated <Anchor path="/privacy-policy">Privacy Policy.</Anchor>
                        </CookieConsent>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Footer.defaultProps = {
    bgcolor: '#F8F8F8',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px'
            }
        }
    }
};

export default Footer
