import styled, {css} from 'styled-components';
// import {device} from '../../theme'

export const LogoWrapper = styled.div `
    display: flex;
    justify-content: ${props => props.justifycontent};
    padding-top: ${props => props.pt};
    padding-bottom: ${props => props.pb};
    a{
        margin-left: -5%;
        img{
            width: 160px;
        }
    }

    ${props => props.whiteLogo && css `
        // .dark-logo {
        //     display: none;
        // }
        // .light-logo {
        //     display: inherit;
        // }
        .dark_logo {
            display: none;
        }
        @media (prefers-color-scheme: dark) {
            background-color: #000;
            .light_logo {
                display: none;
            }
            .dark_logo {
                display: block;
            }
        }
    `}
    ${props => !props.whiteLogo && css `
        .dark-logo {
            display: inherit;
        }
        .light-logo {
            display: none;
        }
    `}
`;